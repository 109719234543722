import React from 'react'

import { ValidationText } from 'components/Field'
import Box from 'components/Primitives/Box'
import { passwordValidationMessagesForUI } from 'modules/Auth/Validation'

interface Props {
    error?: string[] | string
    touched?: boolean
}

const validationMessageOptions = Object.values(passwordValidationMessagesForUI)

const getUniqueErrors = (error: Required<Props>['error']) => {
    const errorArray = Array.isArray(error) ? error : [error]
    return errorArray.filter((err, i) => error.indexOf(err) === i)
}

const PasswordValidationError = ({ error, touched }: Props) => {
    if (!touched || !error) return null

    const uniqueErrors = getUniqueErrors(error)

    return (
        <Box mb={2}>
            {validationMessageOptions
                .filter(msg => !error.includes(msg))
                .map(msg => (
                    <ValidationText key={msg} $color="success">
                        ✅ {msg}
                    </ValidationText>
                ))}
            {uniqueErrors.map(err => (
                <ValidationText key={err.toString()} $color="danger">
                    ❌ {err}
                </ValidationText>
            ))}
        </Box>
    )
}

export default PasswordValidationError
