import type { ReactElement } from 'react'
import React from 'react'

import styled, { css } from 'styled-components'

import type { SizeProps } from '../types/props'

import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'
import type { ColorName } from 'theme'
import { getColor, palette } from 'theme'
import type { Omit } from 'types'

export type LocalCheckboxProps = {
    autoFocus?: boolean
    a11yId?: string
    checked?: boolean
    defaultChecked?: boolean
    disabled?: boolean
    id?: string
    isRequired?: boolean
    label?: string
    name?: string
    state?: string
    value?: boolean | string
    size?: 'small' | 'medium' | 'large'
    onBlur?: React.FocusEventHandler<HTMLInputElement>
    onChange?: React.FormEventHandler<HTMLInputElement>
    onFocus?: React.FocusEventHandler<HTMLInputElement>
    htmlFor?: string
    type?: string
}
export type CheckboxProps = LocalCheckboxProps & BoxProps
type HiddenCheckboxProps = Omit<CheckboxProps, 'size'>

const Checkbox = ({
    autoFocus,
    a11yId,
    checked,
    defaultChecked,
    disabled,
    id,
    isRequired,
    label,
    name,
    state,
    value,
    onBlur,
    onChange,
    onFocus,
    size = 'medium',
    ...props
}: CheckboxProps): ReactElement => (
    <_Checkbox
        aria-describedby="label"
        aria-invalid={state === 'danger'}
        aria-label={a11yId}
        aria-required={isRequired}
        as="label"
        {...props}
    >
        <Box display="block">
            <HiddenCheckbox
                autoFocus={autoFocus}
                checked={checked}
                defaultChecked={defaultChecked}
                disabled={disabled}
                id={id}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={onFocus}
                name={name}
                state={state}
                type="checkbox"
                value={value}
                as="input"
            />

            <CheckboxIcon {...{ size, state }} />
        </Box>
        {label && (
            <_CheckboxLabel id="label" htmlFor={id}>
                {label}
            </_CheckboxLabel>
        )}
    </_Checkbox>
)

export const checkboxDefaultProps: Partial<CheckboxProps> = {
    autoFocus: false,
    disabled: false,
    isRequired: true,
}
Checkbox.defaultProps = checkboxDefaultProps
export default Checkbox

export const _Checkbox = styled(Box)<HiddenCheckboxProps>`
    display: flex;
    align-items: flex-start;
`

const _CheckboxLabel = styled('span')<HiddenCheckboxProps>`
    color: ${palette.neutral[400]};
    font-size: 1rem;
    margin-left: 0.5rem;
`

const CheckboxSizeProperties: { [key: string]: any } = {
    small: css`
        transform: scale(0.6);
    `,
    medium: css`
        transform: scale(0.8);
    `,
    large: css`
        transform: scale(1.2);
    `,
}
export const CheckboxIcon = styled(Box)<{ state?: string; size?: SizeProps }>`
    border: 1px solid ${palette.neutral[200]};
    box-shadow: none;
    border-radius: 0.4rem;
    height: 1.6rem;
    position: relative;
    width: 1.6rem;
    background-color: ${palette.neutral[50]};

    & {
        ${props =>
            props.state &&
            css`
                border-color: ${getColor(props.state as ColorName)};
                box-shadow: ${getColor(props.state as ColorName)} 0px 0px 0px 1px !important;
            `};

        ${(props: any) => props.size && CheckboxSizeProperties[props.size]}
    }
`

const HiddenCheckbox = styled(Box)<any>`
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    width: 1px;

    &[disabled] + ${CheckboxIcon} {
        background-color: ${palette.neutral[50]};
        box-shadow: unset;
    }
    &:checked:focus + ${CheckboxIcon} {
        none;
    }
    &:focus + ${CheckboxIcon} {
        box-shadow: none;
    }
    &:not([disabled]):checked + ${CheckboxIcon} {
        border-color: ${(props: any) => getColor(`${props.state || 'primary'}` as ColorName)};
    }
    &:checked + ${CheckboxIcon} {
        border-color: ${palette.neutral[100]};
        background: ${palette.primary[500]};

        & {
            ${(props: any) =>
                props.state &&
                css`
                    box-shadow: ${palette.primary[500]} 0px 0px 0px 1px !important;
                `};
        }
        &::before {
            background-clip: padding-box;
            content: '';
            left: calc(50% - 0.1875em);
            top: calc(50% - 0.45em);
            position: absolute;

            & {
                border: 0.1em solid white;
                border-left-width: 0;
                border-top-width: 0;
                height: 0.725em;
                transform: rotate(45deg);
                width: 0.425em;
            }
        }
    }
    &[disabled]:checked + ${CheckboxIcon}::before {
        border-color: ${palette.neutral[100]};
    }
`
