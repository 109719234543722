import type { FormikValues } from 'formik'
import type { ObjectSchema} from 'yup';
import { ValidationError } from 'yup'


/*
     Allows us to display a list of validation errors against a
     field instead of just 1. Currently only used for signup, but
     could move to a more global Util if we need in more places
 */

export const returnMultipleFieldErrors =
    <Values extends FormikValues>(schema: ObjectSchema<Values>) =>
    async (values: Values): Promise<Record<string, string[]>> => {
        const messages: Record<string, string[]> = {}

        try {
            await schema.validate(values, { abortEarly: false })
        } catch (error) {
            if (!(error instanceof ValidationError)) {
                throw error
            }

            for (const { path, message } of error.inner) {
                if (path) {
                    messages[path] = (messages[path] || []).concat(message)
                }
            }
        }

        return messages
    }
