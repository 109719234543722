import { useState } from 'react'

import { Field, Form, Formik, type FormikHelpers } from 'formik'
import { useRouter } from 'next/router'
import { useRelayEnvironment } from 'react-relay'
import styled from 'styled-components'
import { object } from 'yup'

import type { SignupFormValues } from './useSignup'
import { useSignup } from './useSignup'
import { acceptTermsValidation, emailValidation, passwordValidation } from './Validation'

import Button from 'components/Button'
import { FormikCheckboxField } from 'components/Checkbox'
import { ValidationText } from 'components/Field'
import HighlightText from 'components/HighlightText'
import Image from 'components/Image'
import { FormikInputField } from 'components/Input'
import { Link } from 'components/Link'
import Paragraph from 'components/Paragraph'
import Box from 'components/Primitives/Box'
import PasswordValidationError from 'containers/Auth/PasswordValidationError'
import { returnMultipleFieldErrors } from 'containers/Auth/utils'
import { privacyLink, termsLink } from 'data'
import appsFlyerPBAClient from 'lib/tracking/appsFlyerPBAClient'
import { useFinanceadsContext } from 'lib/tracking/financeAds/useFinanceadsTracking'
import { useImpactContext } from 'lib/tracking/impact/useImpactTracking'
import useSessionId from 'lib/useSession'
import CaptchaModal from 'modules/Captcha/CaptchaModal'
import useCaptchaModalFlow from 'modules/Captcha/useCaptchaModalFlow'
import emailCongrats from 'public/images/email-congrats.png'
import { trispace, type ThemedComponent } from 'theme'

const SignupStep = {
    Email: 'email',
    Password: 'password',
    Success: 'success',
} as const

type Step = typeof SignupStep[keyof typeof SignupStep]

const stepTexts: Record<Step, { header: string; body: string }> = {
    email: {
        header: 'Become smarter in just 3 minutes',
        body: 'Find out what happened in the markets today – and why you should care – with the free Daily Brief newsletter.',
    },
    password: {
        header: 'Almost there!',
        body: 'Set a password to start receiving the free Finimize Daily Brief in your inbox.',
    },
    success: {
        header: "YOU'RE ALL DONE!",
        body: 'Welcome to the smartest finance community in the world! Check your inbox for your first brief.',
    },
}

const emailValidationSchema = object().shape({
    email: emailValidation,
})

const passwordValidationSchema = object().shape({
    password: passwordValidation,
    gdpr: acceptTermsValidation,
})

const initialValues: SignupFormValues = {
    email: '',
    password: '',
    gdpr: false,
    firstName: '',
    hasOptedInToMarketing: null,
}

type InlineSignupProps = {
    hideBorder?: InlineSignupContainerProps['$hideBorder']
    preventReloadOnSignup?: boolean
    source: 'web_inline' | 'web_top_cta' | 'web_newsletter_landing' | 'web_inline_topics'
}

const InlineSignup = ({
    hideBorder = false,
    source,
    preventReloadOnSignup = false,
}: InlineSignupProps) => {
    const [currentStep, setCurrentStep] = useState<Step>('email')
    const [modalProps, checkForCaptchaErrorAndTriggerFlow] = useCaptchaModalFlow()
    const environment = useRelayEnvironment()
    const impactClient = useImpactContext()
    const financeadsClient = useFinanceadsContext()
    const sessionId = useSessionId(false) || undefined
    const { pathname } = useRouter()
    const router = useRouter()

    const handleSignup = useSignup({
        environment,
        impactTrackingFn: impactClient.track,
        financeadsTrackingFn: financeadsClient.track,
        appsFlyerPBATrackingFn: appsFlyerPBAClient.track,
        sessionId,
        source: source,
        refPage: pathname,
        checkForCaptchaErrorAndTriggerFlow,
    })

    const handleSubmit = async (
        values: SignupFormValues,
        formHelpers: FormikHelpers<SignupFormValues>,
    ) => {
        if (SignupStep.Email === currentStep) {
            setCurrentStep(SignupStep.Password)
            return
        }

        if (SignupStep.Password === currentStep) {
            await handleSignup(values, formHelpers.setStatus, formHelpers.setSubmitting, () => {
                setCurrentStep(SignupStep.Success)
                !preventReloadOnSignup && router.reload()
            })
        }
    }

    return (
        <SignupContainer $hideBorder={hideBorder}>
            <CaptchaModal
                baseId={modalProps.baseId}
                visible={modalProps.visible}
                confirmCaptchaSuccess={modalProps.confirmCaptchaSuccess}
                cancelCaptchaFlow={modalProps.cancelCaptchaFlow}
            />
            <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Box textAlign="left" maxWidth="30rem" flex={1} mb={1}>
                    <SignupHeader>{stepTexts[currentStep].header}</SignupHeader>

                    <SignupBody>{stepTexts[currentStep].body}</SignupBody>
                </Box>
                {SignupStep.Success === currentStep && (
                    <Image
                        display={['none', 'none', 'none', 'block', 'block']}
                        marginTop="auto"
                        marginBottom="auto"
                        width="10rem"
                        ml={3}
                        src={emailCongrats}
                        alt="Email congratulations"
                    />
                )}
            </Box>

            {SignupStep.Success !== currentStep && (
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={
                        currentStep === 'email' ? emailValidationSchema : passwordValidationSchema
                    }
                    validate={
                        currentStep === 'password'
                            ? returnMultipleFieldErrors(passwordValidationSchema)
                            : undefined
                    }
                >
                    {({ isSubmitting, status, errors, touched }) => (
                        <Form>
                            <SignUpElementsContainer>
                                <Box flex={2} minWidth="15rem">
                                    {SignupStep.Email === currentStep && (
                                        <Field
                                            name="email"
                                            placeholder="Email address"
                                            component={FormikInputField}
                                        />
                                    )}
                                    {SignupStep.Password === currentStep && (
                                        <>
                                            <Field
                                                name="password"
                                                type="password"
                                                placeholder="Password"
                                                hideValidationText
                                                component={FormikInputField}
                                            />
                                            <PasswordValidationError
                                                touched={touched.password}
                                                error={errors.password}
                                            />
                                        </>
                                    )}
                                </Box>

                                <Box flex={1} minWidth="15rem">
                                    <Button
                                        isLoading={isSubmitting}
                                        size="medium"
                                        type="submit"
                                        fullWidth
                                    >
                                        {SignupStep.Email === currentStep
                                            ? 'Sign Up Free'
                                            : 'Complete Sign Up'}
                                    </Button>
                                </Box>

                                {SignupStep.Password === currentStep && (
                                    <TermsContainer>
                                        <Field
                                            name="gdpr"
                                            component={FormikCheckboxField}
                                            checkboxLabel={
                                                <TermsText>
                                                    I agree to the finimize{' '}
                                                    <TextLink
                                                        url={termsLink}
                                                        color="primary.500"
                                                        target="_blank"
                                                    >
                                                        Terms
                                                    </TextLink>{' '}
                                                    and{' '}
                                                    <TextLink
                                                        url={privacyLink}
                                                        color="primary.500"
                                                        target="_blank"
                                                    >
                                                        Privacy Policy
                                                    </TextLink>
                                                </TermsText>
                                            }
                                        />
                                    </TermsContainer>
                                )}
                            </SignUpElementsContainer>

                            {status?.formError && (
                                <ValidationText $color="danger">{status.formError}</ValidationText>
                            )}
                        </Form>
                    )}
                </Formik>
            )}
        </SignupContainer>
    )
}

const SignUpElementsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 1rem;
`

const TextLink = styled(Link)`
    color: ${({ theme }) => theme.palette.primary[500]};
`

const SignupHeader = styled(HighlightText)<ThemedComponent>`
    font-size: ${({ theme }) => theme.fontSize.L};
    font-weight: ${({ theme }) => theme.fontWeights.black};
    text-transform: uppercase;
`

const SignupBody = styled(Paragraph)<ThemedComponent>`
    font-size: ${({ theme }) => theme.fontSize.XS};
    font-family: ${trispace.style.fontFamily};
    margin-top: 1rem;
`

type InlineSignupContainerProps = {
    $hideBorder?: boolean
}

const SignupContainer = styled(Box)<InlineSignupContainerProps>`
    width: 100%;
    background: ${({ theme }) => theme.palette.neutral[100]};
    border-top: ${({ theme, $hideBorder }) =>
        $hideBorder ? 'transparent' : '2px solid' + theme.palette.primary[500]};
    padding: 1.5rem 2rem 2rem 1.5rem;
`

const TermsContainer = styled.div`
    margin-top: 12px;
    width: 100%;
`

const TermsText = styled(Paragraph)`
    font-size: 12px;
    margin-top: 5px;
    color: ${({ theme }) => theme.palette.grey5};
`

export default InlineSignup
