import { useState, useRef, useEffect } from 'react'

import { useDialogState } from 'reakit/Dialog'

import type { CaptchaModalProps } from 'modules/Captcha/CaptchaModal'
import { wafCaptchaErrorMessage } from 'modules/Captcha/consts'

type RequestFn = (...args: any) => Promise<void>

type CaptchaFlowState =
    | {
          status: 'None'
          resubmitRequest: null
      }
    | {
          status: 'CaptchaModalInProgress' | 'CaptchaSuccess'

          resubmitRequest: RequestFn
      }

type _startCaptchaFlow = <T extends RequestFn>(resubmitRequest: T) => void

type HasTriggeredCaptchaFlow = boolean
type CheckForCaptchaErrorAndTriggerFlow = <T extends RequestFn>(
    errorMessage: string,
    resubmitRequest: T,
) => HasTriggeredCaptchaFlow

type HookReturn = [CaptchaModalProps, CheckForCaptchaErrorAndTriggerFlow]

const useCaptchaModalFlow = (): HookReturn => {
    const [captchaFlowState, setCaptchaFlowState] = useState<CaptchaFlowState>({
        status: 'None',
        resubmitRequest: null,
    })
    const dialog = useDialogState({ animated: true })

    // Use a ref to keep track of whether the resubmitted request has been made or not
    // Advantage of this over a state value is changing this won't cause a re-render
    const hasResubmitBeenTriggered = useRef(false)

    useEffect(() => {
        // Check if the modal is not visible and the status is 'CaptchaSuccess'
        // This means the user has completed the puzzle and the modal has now been hidden
        // meaning we are safe to continue the login/signup/whatever process as normal
        if (
            !dialog.visible &&
            captchaFlowState.status === 'CaptchaSuccess' &&
            captchaFlowState.resubmitRequest
        ) {
            // Check if the resubmitRequest has not been triggered yet
            // We do this because we only want to re-trigger the request once
            if (!hasResubmitBeenTriggered.current) {
                hasResubmitBeenTriggered.current = true // Mark as triggered
                captchaFlowState.resubmitRequest() // Trigger the request

                // Update the captchaFlowState status to prevent further triggers
                setCaptchaFlowState({
                    resubmitRequest: null,
                    status: 'None', // Or some other status
                })
            }
        } else {
            hasResubmitBeenTriggered.current = false // Reset for the next time
        }
    }, [dialog.visible, captchaFlowState.status, setCaptchaFlowState])

    /**
     * This is used to initiate the state change once the captcha process starts
     * and show the modal. It is an internal function since any caller would use the
     * checkForCaptchaErrorAndTriggerFlow function
     */
    const _startCaptchaFlow: _startCaptchaFlow = resubmitRequest => {
        setCaptchaFlowState({
            status: 'CaptchaModalInProgress',
            resubmitRequest,
        })
        dialog.show()
    }

    /** This is fired once the user has completed the puzzle
     * It should update the hook state and initiate the closing of the modal so
     * we can continue the original flow */
    const confirmCaptchaSuccess = (): void => {
        dialog.hide()
        const { resubmitRequest } = captchaFlowState

        // At this point in the flow resubmitRequest should always be required
        // TODO make more robust
        if (!resubmitRequest) {
            throw new Error('resubmitRequest should be defined')
        }

        setCaptchaFlowState({
            status: 'CaptchaSuccess',
            resubmitRequest,
        })
    }

    /**
     * This is used to reset the captcha modal state if the user cancels
     * the flow (e.g. if the captcha modal is hidden)
     */
    const cancelCaptchaFlow = (): void => {
        dialog.hide()
        setCaptchaFlowState({
            status: 'None',
            resubmitRequest: null,
        })
    }

    /**
     * This is used on receiving and error from the server request we are trying to protect
     * It will check whether the error matches the error string we throw in our middleware
     * if the response is a 405 https://docs.aws.amazon.com/waf/latest/developerguide/waf-js-captcha-api-conditional.html
     */
    const checkForCaptchaErrorAndTriggerFlow: CheckForCaptchaErrorAndTriggerFlow = (
        errorMessage,
        resubmitRequest,
    ) => {
        if (errorMessage === wafCaptchaErrorMessage) {
            _startCaptchaFlow(resubmitRequest)
            return true
        } else {
            // If it's not a WAF captcha response we want the caller to handle it
            // as normal
            return false
        }
    }

    const modalProps: CaptchaModalProps = {
        visible: dialog.visible,
        baseId: dialog.baseId,
        confirmCaptchaSuccess,
        cancelCaptchaFlow,
    }

    return [modalProps, checkForCaptchaErrorAndTriggerFlow]
}

export default useCaptchaModalFlow
