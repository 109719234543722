import { useRouter } from 'next/router'
import styled from 'styled-components'

import type { LinkProps } from 'components/Link'
import { LegacyLink as Link } from 'components/Link'
import type { ParagraphProps } from 'components/Paragraph'
import Paragraph from 'components/Paragraph'
import type { BoxProps } from 'components/Primitives/Box'
import type { TagPlacementEnum } from 'lib/analyticsApi'
import { trackTagClicked } from 'lib/analyticsApi'
import { routeMap } from 'shared/routes'
import type { ThemedComponent } from 'theme'
import { trispace } from 'theme'

type TrackingParams = {
    placement: TagPlacementEnum
}

export type TagProp = {
    name: string
    slug: string
}
export type TagProps = {
    tag: TagProp
    trackingParams?: TrackingParams
    fontSize: ParagraphProps['fontSize']
}

/**
 * @param tag: the relay key for tag
 * @param trackingParams: tracking params for mixpanel
 * @returns a tag
 */
const Tag = ({ tag, trackingParams, fontSize }: TagProps) => {
    const { name, slug } = tag

    const { pathname } = useRouter()
    const url = routeMap.topics(!!slug ? [slug] : [])

    const trackClick = () =>
        !!trackingParams &&
        trackTagClicked({ tagName: name, location: pathname, ...trackingParams })

    if (!name) return null

    const TagName = () => {
        return (
            <Paragraph
                fontSize={fontSize}
                color="accent.500"
                fontFamily={trispace.style.fontFamily}
                m={0}
                p={0}
                data-cy={`tag-linked-${name}`}
            >
                {name}
            </Paragraph>
        )
    }

    return (
        <>
            <TagLink url={url} onClick={trackClick}>
                <TagName />
            </TagLink>
        </>
    )
}

type TagBoxProps = BoxProps
type TagLinkProps = LinkProps

const TagLink = styled(Link)<ThemedComponent<TagLinkProps>>`
    display: flex;
    width: fit-content;
    cursor: pointer;

    vertical-align: middle;
    justify-content: start;

    min-width: 1rem;
    max-width: ${(props: TagBoxProps | TagLinkProps) => props.maxWidth ?? '100%'};

    & > p {
        font-weight: 300;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        text-align: left;
        word-break: break-all;
        &:hover {
            text-decoration: underline;
        }
    }

    :link {
        text-decoration: none;
    }

    a:visited,
    active {
        text-decoration: none;
    }
`

export default Tag
