import React, { useEffect } from 'react'

import type { CheckboxProps} from 'reakit/Checkbox';
import { Checkbox, useCheckboxState } from 'reakit/Checkbox'
import styled from 'styled-components'

import type { BoxProps } from 'components/Primitives/Box';
import Box from 'components/Primitives/Box'
import { fonts } from 'theme'

export type CheckboxButtonProps = {
    children: React.ReactNode
} & CheckboxProps &
    BoxProps

export type CheckboxButtonGroupProps = {
    children: React.ReactNode
    defaultValues?: (string | number)[]
    onChange?: (state: (string | number)[]) => void
}

export const CheckboxButtonGroup = ({
    onChange,
    defaultValues = [],
    children,
}: CheckboxButtonGroupProps) => {
    const checkbox = useCheckboxState({ state: defaultValues })
    const state = checkbox.state as (string | number)[]

    useEffect(() => {
        onChange && onChange(state)
    }, [checkbox.state])

    return (
        <>
            {React.Children.map(children as React.ReactElement, (child: React.ReactNode) => {
                if (!React.isValidElement(child)) return null
                return React.cloneElement(child, { ...checkbox })
            })}
        </>
    )
}
const _box = styled(Box)<Partial<CheckboxButtonProps>>`
    cursor: pointer;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: ${fonts.weight.semibold};

    &:hover {
        background: ${({ theme }) => theme.palette.primary[200]};
        color: ${({ theme }) => theme.palette.secondary[700]};
    }

    &[aria-checked='true'] {
        background: ${({ theme }) => theme.palette.priamry[500]};
        color: ${({ theme }) => theme.palette.neutral[0]};
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
    }
`

export const CheckboxButton = ({
    children,
    checked,
    onChange,
    value,
    ...props
}: CheckboxButtonProps) => {
    const checkbox = useCheckboxState({ state: checked })

    return (
        <Checkbox
            as={_box}
            bg="white"
            color="grey900"
            width="100%"
            borderRadius="default"
            height="3rem"
            fontSize={['14px', null, '16px']}
            {...checkbox}
            {...{ onChange, value }}
            {...props}
        >
            {children}
        </Checkbox>
    )
}
