import { useRouter } from 'next/router'

import Button from 'components/Button'
import useHasLoadedOnClient from 'lib/useHasLoadedOnClient'
import { loginRedirectParam } from 'modules/SignUpOrLogin/Hooks/useLoginRedirect'
import { routeMap } from 'shared/routes'

const NavLogin = ({
    isAuthenticated,
    iconOnly = false,
}: {
    isAuthenticated: boolean
    iconOnly?: boolean
}) => {
    const { asPath } = useRouter()
    const loginParams = {
        [loginRedirectParam]: encodeURIComponent(asPath),
    }

    const loaded = useHasLoadedOnClient()

    return (
        <>
            {isAuthenticated ? (
                <Button
                    data-cy="profile-button"
                    variant="secondary"
                    size="small"
                    url={loaded ? routeMap.profile : undefined}
                    icon="RiUserLine"
                >
                    {!iconOnly ? 'Profile' : ''}
                </Button>
            ) : (
                <Button
                    data-cy="login-button"
                    variant="secondary"
                    size="small"
                    url={loaded ? { pathname: routeMap.login, query: loginParams } : undefined}
                    icon="RiUserLine"
                >
                    {!iconOnly ? 'Log in' : ''}
                </Button>
            )}
        </>
    )
}

export default NavLogin
