import React from 'react'

import type { FieldProps} from '../Field';
import Field, { fieldDefaultProps } from '../Field'

import type { LocalCheckboxProps, CheckboxProps} from './Checkbox';
import Checkbox, { checkboxDefaultProps } from './Checkbox'

import type { Omit } from 'types'

export type LocalCheckboxFieldProps = Omit<FieldProps, 'children'> &
    LocalCheckboxProps & {
        checkboxLabel?: string
    }
export type CheckboxFieldProps = LocalCheckboxFieldProps & CheckboxProps

export const CheckboxField = ({
    autoFocus,
    a11yId,
    checkboxLabel,
    checked,
    className,
    defaultChecked,
    disabled,
    hint,
    label,
    id,
    isOptional,
    isRequired,
    onBlur,
    onChange,
    onFocus,
    size,
    state,
    name,
    validationText,
    value,
    ...props
}: CheckboxFieldProps) => (
    <Field
        {...{ a11yId, hint, isOptional, isRequired, state, validationText, name, label }}
        {...props}
    >
        {({ fieldElementProps }) => (
            <Checkbox
                {...{
                    autoFocus,
                    checked,
                    className,
                    defaultChecked,
                    disabled,
                    id,
                    size,
                    isRequired,
                    name,
                    state,
                    value,
                    onBlur,
                    onChange,
                    onFocus,
                }}
                label={checkboxLabel}
                {...fieldElementProps}
            />
        )}
    </Field>
)

CheckboxField.defaultProps = {
    ...checkboxDefaultProps,
    ...fieldDefaultProps,
}

CheckboxField.displayName = 'CheckboxField'
export default CheckboxField
