import { useRouter } from 'next/router'

/*

    to convert params into a link for a content piece

    if specified, it can also pull additional params (offer slug, email-id) from the url directly

*/

export function useContentPieceUrl(
    canonicalLink: string | null,
    contentId: string,
    slug?: string,
    getParamsFromUrlIfExists?: boolean,
): string
export function useContentPieceUrl(
    canonicalLink: string | null,
    contentId?: string,
    slug?: string,
    getParamsFromUrlIfExists?: boolean,
): string | null
export function useContentPieceUrl(
    canonicalLink: string | null,
    contentId?: string,
    slug = '',
    getParamsFromUrlIfExists?: boolean,
): string | null {
    const router = useRouter()

    if (!contentId) {
        return null
    }

    if (canonicalLink) {
        // We just want the path of the content piece, otherwise it will
        // get treated like an external link and open in a new tab
        return parseURLPath(canonicalLink)
    }

    if (getParamsFromUrlIfExists) {
        const { query: params = {} } = router

        const offerSlugParam = params['offer-slug'] ? `offer-slug=${params.offerSlug}` : null
        const encryptedEmailParam = params['email-id'] ? `email-id=${params['email-id']}` : null

        // form extra params if they exist
        const extraParams =
            // if both then chain both
            offerSlugParam && encryptedEmailParam
                ? `?${offerSlugParam}&${encryptedEmailParam}`
                : // if either then just display that one
                offerSlugParam || encryptedEmailParam
                ? `?${offerSlugParam || encryptedEmailParam}`
                : // else no extra params needed
                  ``

        return createContentUrl({ contentId, slug, extraParams })
    }

    return createContentUrl({ contentId, slug })
}

export const getContentPieceUrl = useContentPieceUrl

// Abstracts the building of a content url out of the hook function so it can be used
// outside of components, ie getIntialProps
export const createContentUrl = ({
    contentId,
    slug = '',
    extraParams = '',
}: {
    contentId: string
    slug?: string
    extraParams?: string
}): string => `/content/${contentId}/${slug}${extraParams}`

type UseContentUrlProps = {
    id: string
    slug: string
    canonicalLink: string
}

export const useContentUrl = (contentPiece: UseContentUrlProps) => {
    const { id, slug, canonicalLink: contentUrl } = contentPiece

    return useContentPieceUrl(contentUrl, id, slug, true)
}

export const parseURLPath = (url?: string | null): string => {
    if (!url) return '/'
    const parsedURL = new URL(url)

    return parsedURL.pathname
}
