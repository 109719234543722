import { graphql } from 'react-relay'
import type { Environment } from 'relay-runtime'

import type { signUpWithEmailMutationResponse } from '__generated__/signUpWithEmailMutation.graphql'
import createMutationPromise from 'lib/createMutationPromise'

const signUpWithEmailMutation =
    (relayEnv: Environment) =>
    (
        email: string,
        firstName = '',
        password: string,
        hasOptedInToMarketing: boolean | null,
        source?: string,
        sessionId?: string,
    ) =>
        createMutationPromise<signUpWithEmailMutationResponse>(relayEnv)({
            // @ts-ignore
            mutation: graphql`
                mutation signUpWithEmailMutation($input: SignUpWithEmailInput!) {
                    signUpWithEmail(input: $input) {
                        accessToken
                        viewer {
                            me {
                                id
                            }
                        }
                    }
                }
            `,
            variables: {
                input: { firstName, email, password, hasOptedInToMarketing, source, sessionId },
            },
        })

export default signUpWithEmailMutation
