/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type SignUpWithEmailInput = {
    email: string;
    password: string;
    firstName: string;
    lastName?: string | null | undefined;
    hasOptedInToMarketing?: boolean | null | undefined;
    source?: string | null | undefined;
    sessionId?: string | null | undefined;
    clickedAdContentPieceId?: number | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type signUpWithEmailMutationVariables = {
    input: SignUpWithEmailInput;
};
export type signUpWithEmailMutationResponse = {
    readonly signUpWithEmail: {
        readonly accessToken: string;
        readonly viewer: {
            readonly me: {
                readonly id: string;
            } | null;
        };
    } | null;
};
export type signUpWithEmailMutation = {
    readonly response: signUpWithEmailMutationResponse;
    readonly variables: signUpWithEmailMutationVariables;
};



/*
mutation signUpWithEmailMutation(
  $input: SignUpWithEmailInput!
) {
  signUpWithEmail(input: $input) {
    accessToken
    viewer {
      me {
        __typename
        id
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessToken",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "signUpWithEmailMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SignUpWithEmailPayload",
        "kind": "LinkedField",
        "name": "signUpWithEmail",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "me",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "signUpWithEmailMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SignUpWithEmailPayload",
        "kind": "LinkedField",
        "name": "signUpWithEmail",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "me",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "249323ab39378a2b21121b30acd737ce",
    "id": null,
    "metadata": {},
    "name": "signUpWithEmailMutation",
    "operationKind": "mutation",
    "text": "mutation signUpWithEmailMutation(\n  $input: SignUpWithEmailInput!\n) {\n  signUpWithEmail(input: $input) {\n    accessToken\n    viewer {\n      me {\n        __typename\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '64d993bfb1d008cf787e0f1cdcbcaf48';
export default node;
