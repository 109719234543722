import { useCallback, useState } from 'react'

const useDropdownDrawer = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleDrawer = useCallback((forceState?: boolean) => {
        setIsOpen(prev => forceState ?? !prev)
    }, [])

    return {
        isOpen,
        toggleDrawer,
        closeDrawer: () => toggleDrawer(false),
        openDrawer: () => toggleDrawer(true),
    }
}

export default useDropdownDrawer
