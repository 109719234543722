import { v4 as uuidv4 } from 'uuid'

import { getCookie, setCookie, clearCookie } from './useCookies'

const createAndSetSessionId = () => {
    const sessionId = uuidv4()
    setCookie('session_id', sessionId)
    return sessionId
}

const useSessionId = (isAuthenticated: boolean): string | null => {
    let sessionId = getCookie('session_id')

    if (!sessionId) {
        sessionId = createAndSetSessionId()
    } else if (isAuthenticated) {
        clearCookie('session_id')
        return null
    }

    return sessionId
}

export default useSessionId
