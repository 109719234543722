import styled from 'styled-components'

import { TOP_NAV_HEIGHT } from '../consts'

import useDropdownDrawer from './useDropdownDrawer'

import Button from 'components/Button'
import MaxWidthContainer from 'containers/MaxWidthContainer'
import { CTAPlacement } from 'lib/analyticsApi'
import InlineSignup from 'modules/Auth/InlineSignup'

type NewsletterSignupDropdownProps = {
    onClick: (trackingName: CTAPlacement.NEWSLETTER_SIGNUP_DROPDOWN) => void
}

const NewsletterSignupDropdown = ({ onClick }: NewsletterSignupDropdownProps) => {
    const { isOpen, toggleDrawer, closeDrawer } = useDropdownDrawer()

    const handleClick = () => {
        onClick?.(CTAPlacement.NEWSLETTER_SIGNUP_DROPDOWN)
        toggleDrawer()
    }

    return (
        <>
            <Button
                icon="RiNewspaperLine"
                variant="secondary"
                size="small"
                onClick={handleClick}
                data-cy="purchase-cta-button"
            >
                Subscribe
            </Button>

            {isOpen && (
                <DrawerWrapper>
                    <DrawerContainer>
                        <MaxWidthContainer centraliseChildren>
                            <InlineSignup hideBorder source="web_top_cta" />
                        </MaxWidthContainer>
                    </DrawerContainer>

                    <Backdrop onClick={closeDrawer}></Backdrop>
                </DrawerWrapper>
            )}
        </>
    )
}

const DrawerWrapper = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100vw;
    margin-left: 0;
    height: calc(100vh - ${TOP_NAV_HEIGHT}px);
    z-index: ${({ theme }) => theme.zIndex.layer06};
    margin-top: ${TOP_NAV_HEIGHT}px !important;
    display: flex;
    flex-direction: column;
`

const DrawerContainer = styled.div`
    z-index: ${({ theme }) => theme.zIndex.layer07};
    width: 100%;

    background: ${({ theme }) => theme.palette.neutral[100]};
    border-top: 2px solid ${({ theme }) => theme.palette.primary[500]};
`

const Backdrop = styled.div`
    background: rgba(0, 0, 0, 0.3);
    opacity: 1;
    transition: opacity 0.4s ease;
    flex: 1;
`

export default NewsletterSignupDropdown
