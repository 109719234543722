import { useEffect } from 'react'

import type { DialogProps } from 'reakit/Dialog'
import styled from 'styled-components'

import Card from 'components/Card'
import Icon from 'components/Icon'
import Modal from 'components/Modal'
import Box from 'components/Primitives/Box'
import { zIndex } from 'theme'

export type CaptchaModalProps = {
    baseId: DialogProps['baseId']
    visible: DialogProps['visible']
    confirmCaptchaSuccess: () => void
    cancelCaptchaFlow: () => void
}

export const CAPTCHA_CSS_ID = 'captcha-container'
export const CAPTCHA_MODAL_ARIA = 'Captcha modal'

// Have to cast env vars to assume they are defined
const apiKey = process.env.NEXT_PUBLIC_AWS_WAF_API_KEY as string

const CaptchaModal = ({
    baseId,
    visible,
    confirmCaptchaSuccess,
    cancelCaptchaFlow,
}: CaptchaModalProps) => {
    useEffect(() => {
        if (visible && typeof AwsWafCaptcha !== 'undefined') {
            const container = document.querySelector('#' + CAPTCHA_CSS_ID)
            AwsWafCaptcha.renderCaptcha(container, {
                apiKey,
                onSuccess: () => {
                    // Try loading again, now that there is a valid CAPTCHA token
                    confirmCaptchaSuccess()
                },
            })
        }
    }, [visible])

    return (
        <StyledModal
            baseId={baseId}
            visible={visible}
            ariaLabel={CAPTCHA_MODAL_ARIA}
            data-cy="waf-captcha-modal"
        >
            <Box display="flex" alignItems="center" width="100%" pt={2}>
                <Card elevation={2} radius="medium" maxWidth="475px" p={2} margin="auto">
                    <Box pb={1} marginLeft="auto" onClick={cancelCaptchaFlow}>
                        <StyledIcon size="default" icon="RiCloseLine" title="close" />
                    </Box>
                    <Box id={CAPTCHA_CSS_ID} />
                </Card>
            </Box>
        </StyledModal>
    )
}

const StyledModal = styled(Modal)`
    z-index: ${zIndex.layerTop}; // This is to ensure the modal is always on top of everything else, e.g. the navigation bars
`

const StyledIcon = styled(Icon)`
    float: right;
    cursor: pointer;
`

export default CaptchaModal
