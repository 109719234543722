/**
 * await this function to run a check on whether the user is a bot
 * before running the mutation
 */
export const checkUserIsNotBot = async (onFailBotCheck: () => void): Promise<boolean> => {
    let didPassBotCheck = true
    // This branch should block the mutation if a request fails WAF's browser check
    // If AwsWafIntegration is undefined it means the script hasn't load (see _app.ts)
    if (typeof AwsWafIntegration !== 'undefined') {
        await AwsWafIntegration.getToken().catch(() => {
            didPassBotCheck = false
            onFailBotCheck()
        })
    }
    return didPassBotCheck
}
