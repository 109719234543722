import { string, boolean } from 'yup'

export const VERIFY_EMAIL_MESSAGE = 'Please verify your email address is correct'
export const TERMS_VALIDATION_MESSAGE = 'Please tick to agree with our terms.'
export const MARKETING_OPT_IN_MESSAGE = 'Sign up for exclusive offers and event information'

// Need to manage this dict to have a list of errors
// to show in the UI. We want to show any requirements that
// have been met in green, and those that haven't in red.
// Any messages added here will be displayed if there's a field error
export const passwordValidationMessagesForUI = {
    LENGTH: 'At least 8 characters long',
    NUMBER: 'Include at least 1 number',
    LOWER_UPPER_CASE: 'Include upper and lower case',
}

const firstNameValidation = string().when('props.variant', {
    is: 'page',
    then: string()
        .min(2, "Short, snappy, we like it. This form didn't though: please enter a longer name.")
        .max(30, 'Fantastic name, but a little long for us... got anything shorter?')
        .required('Please enter a name... we‘d love to be introduced properly.'),
})

const emailValidation = string()
    .trim()
    .email(VERIFY_EMAIL_MESSAGE)
    .required('Please enter a valid email address')

const passwordValidation = string()
    .required('Please provide a password')
    .min(8, passwordValidationMessagesForUI.LENGTH)
    .max(250, 'Must be less than 250 characters long')
    .matches(/[a-z]/, passwordValidationMessagesForUI.LOWER_UPPER_CASE)
    .matches(/[A-Z]/, passwordValidationMessagesForUI.LOWER_UPPER_CASE)
    .matches(/[0-9]/, passwordValidationMessagesForUI.NUMBER)

const acceptTermsValidation = boolean().oneOf([true], TERMS_VALIDATION_MESSAGE)

const marketingOptInValidation = boolean().nullable().notRequired()

export {
    emailValidation,
    passwordValidation,
    acceptTermsValidation,
    firstNameValidation,
    marketingOptInValidation,
}
