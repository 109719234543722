import NewsletterSignupDropdown from './NewsletterSignupDropdown'

import Button from 'components/Button'
import { CTAPlacement } from 'lib/analyticsApi'

type NavCTAProps = {
    isAuthenticated: boolean
    showCTA: boolean
    freeTrialUrl: string
    onCTAClick: (placement: CTAPlacement) => void
}

const NavCTA = ({ isAuthenticated, showCTA, freeTrialUrl, onCTAClick }: NavCTAProps) => {
    if (!showCTA) return null

    return (
        <>
            {!isAuthenticated ? (
                <NewsletterSignupDropdown onClick={onCTAClick} />
            ) : (
                <Button
                    url={freeTrialUrl}
                    size="small"
                    data-cy="purchase-cta-button"
                    onClick={() => onCTAClick(CTAPlacement.NAV_BAR)}
                >
                    Go Pro
                </Button>
            )}
        </>
    )
}

export default NavCTA
